<template>
    <div>
        <div class="mb-40 mb-lg-60 text-center">
            <h3 class="tit tit--md">
                {{code}}
            </h3>
        </div>

        <div class="mb-40 mb-lg-60">
            <div class="mb-8 mb-lg-20">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <h4 class="tit tit--sm line-height-1">
                            후원방법
                        </h4>
                    </v-col>
                    <v-col>
                        <p class="form__caption mb-0">* 필수입력 항목입니다.</p>
                    </v-col>
                </v-row>
            </div>

            <div class="form--primary">
                <form-row tit="후원유형">
                    <p class="font-size-14 grey-6--text">{{code}}</p>
                </form-row>
                <form-row v-if="$route.query._campaign" tit="캠페인">
                    <p class="font-size-14 grey-6--text">{{campaign}}</p>
                </form-row>
                <form-row tit="은행명" pointer>
                    <v-text-field v-model="sponsor.bank" placeholder="예금하실 은행명을 입력하세요." outlined hide-details />
                </form-row>
                <form-row tit="예금주" pointer>
                    <v-text-field v-model="sponsor.accountHolder" placeholder="예금주명을 입력하세요." outlined hide-details />
                </form-row>
                <form-row tit="계좌번호" pointer>
                    <v-text-field v-model="sponsor.accountNum" placeholder="계좌번호를 입력하세요." outlined hide-details />
                </form-row>
                <form-row tit="예금주 생년월일" pointer>
                    <v-text-field v-model="sponsor.birth" placeholder="생년월일을 입력하세요." hint="*개인: 주민번호 앞 6자리, *법인: 사업자등록번호 10자리" persistent-hint outlined/>
                </form-row>
                <form-row tit="예금주 연락처" pointer>
                    <v-row class="row--x-small">
                        <v-col cols="4" lg="auto">
                            <v-select :items="prependPhones" v-model="sponsor.phone1" outlined hide-details="" class="w-lg-200px"/>
                        </v-col>
                        <v-col cols="8" lg="">
                            <v-text-field v-model="sponsor.phone2" placeholder="‘-’를 제외하고 입력하세요." outlined hide-details />
                        </v-col>
                    </v-row>  
                </form-row>
                <form-row tit="약정금액" pointer>
                    <v-radio-group v-model="sponsor.amount.support" row hide-details>
                        <v-radio label="1만원" value="1만원"/>
                        <v-radio label="2만원" value="2만원"/>
                        <v-radio label="3만원" value="3만원"/>
                        <v-radio label="5만원" value="5만원"/>
                        <v-radio label="10만원" value="10만원"/>
                        <v-radio label="기타" value="기타"></v-radio>
                        <div class="d-flex align-center" v-if="sponsor.amount.support === '기타'">
                            <v-text-field v-model="sponsor.amount.priceEtc" outlined hide-details class="w-60px" />
                            <p class="font-size-14 grey-6--text ml-4 ml-lg-8">만원</p>
                        </div>
                    </v-radio-group>
                </form-row>
                <form-row tit="출금일" pointer>
                    <v-radio-group v-model="sponsor.amount.day" row hide-details>
                        <v-radio label="1일" value="1일"/>
                        <v-radio label="10일" value="10일"/>
                        <v-radio label="25일" value="25일"/>
                        <v-radio label="30일" value="30일"/>
                        <v-radio label="기타" value="기타"></v-radio>
                        <div class="d-flex align-center" v-if="sponsor.amount.day === '기타'">
                            <v-text-field v-model="sponsor.amount.dayEtc" outlined hide-details class="w-60px" />
                            <p class="font-size-14 grey-6--text ml-4 ml-lg-8">일</p>
                        </div>
                    </v-radio-group>
                </form-row>
                <form-row tit="기타 요청사항">
                    <v-textarea v-model="sponsor.memo" rows="9" placeholder="요청사항을 적어주세요." outlined hide-details />
                </form-row>
            </div>
        </div>

        <div class="mb-40 mb-lg-60">
            <div class="mb-8 mb-lg-20">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <h4 class="tit tit--sm line-height-1">
                            기본정보
                        </h4>
                    </v-col>
                    <v-col>
                        <p class="form__caption mb-0">* 필수입력 항목입니다.</p>
                    </v-col>
                </v-row>
            </div>

            <div class="form--primary">
                <form-row tit="성명(단체명)" pointer>
                    <v-text-field v-model="primary.name" placeholder="이름을 혹은 단체명을 입력하세요." outlined hide-details />
                </form-row>
                <form-row tit="추천인" pointer>
                    <v-text-field v-model="primary.recommender" placeholder="추천인 이름을 입력하세요." outlined hide-details />
                </form-row>
                <form-row tit="주민등록번호" pointer>
                    <v-row class="row--x-small">
                        <v-col cols="6">
                            <v-text-field v-model="primary.residentNum1" placeholder="주민등록번호 앞자리를 입력하세요." outlined hide-details="" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="primary.residentNum2" placeholder="주민등록번호 뒷자리를 입력하세요." outlined hide-details />
                        </v-col>
                    </v-row>
                    <div class="mt-4 px-16">
                        <p class="font-size-12 grey-9--text">*소득공제 영수증 발급을 원하실 경우 주민등록번호가 필요합니다.</p>
                    </div>
                </form-row>
                <form-row tit="연락처" pointer>
                    <v-row class="row--x-small">
                        <v-col cols="4" lg="auto">
                            <v-select :items="prependPhones" v-model="primary.phone1" outlined hide-details="" class="w-lg-200px"/>
                        </v-col>
                        <v-col cols="8" lg="">
                            <v-text-field v-model="primary.phone2" placeholder="‘-’를 제외하고 입력하세요." outlined hide-details />
                        </v-col>
                    </v-row>
                </form-row>
                <form-row tit="주소" pointer>
                    <v-row class="row--x-small">
                        <v-col cols="">
                            <v-text-field v-model="primary.postcode" @click="searchAddress" readonly placeholder="우편번호" outlined hide-details />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn @click="searchAddress" large color="grey-6" class="h-100 w-80px w-lg-100px"><span class="white--text">검색</span></v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="primary.address1" @click="searchAddress" readonly placeholder="기본주소" outlined hide-details />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="primary.address2" placeholder="상세주소" outlined hide-details />
                        </v-col>
                    </v-row>
                </form-row>
                <form-row tit="소득공제 영수증" pointer>
                    <v-radio-group v-model="primary.tax" row hide-details>
                        <v-radio label="필요" value="true"/>
                        <v-radio label="불필요" value="false"/>
                    </v-radio-group>
                </form-row>
            </div>
        </div>

        <div class="mb-40 mb-lg-60">
            <div class="mb-16 mb-lg-24">
                <div class="mb-8 mb-lg-16">
                    <v-row align="center" justify="space-between">
                        <v-col cols="12" md="">
                            <h4 class="tit tit--sm line-height-1">
                                개인정보 수집 및 이용 동의
                            </h4>
                        </v-col>
                        <v-col cols="12" md="" class="d-flex justify-end">
                            <v-radio-group v-model="agree1" row hide-details class="v-radio-group--sm">
                                <v-radio label="동의함" :value="true"/>
                                <v-radio label="동의안함" :value="false"/>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </div>
                <v-card elevation="0" outlined style="border-color: #ececec">
                    <div class="pa-20">
                        <ul class="dot-texts page-text page-text--sm">
                            <li class="dot-text dot-text--sm">
                                수집 및 이용목적 : 효성 CMS 출금이체를 통한 요금수납, 소득공제 영수증 발급
                            </li>
                            <li class="dot-text dot-text--sm">
                                수집항목 : 성명, 생년월일, 연락처, 예금주명, 계좌번호, 예금주 휴대전화번호
                            </li>
                            <li class="dot-text dot-text--sm">
                                보유 및 이용 기간 : 수집, 이용 동의 일로부터 자동이체 종료일(해지일) 까지
                            </li>
                            <li class="dot-text dot-text--sm">
                                신청자는 개인정보 수집 및 이용을 거부할 권리가 있으며, 권리 행사 시 출금이체 신청이 거부될 수 있습니다.
                            </li>
                        </ul>
                    </div>
                </v-card>
            </div>
            <div class="mb-16 mb-lg-24">
                <div class="mb-8 mb-lg-16">
                    <v-row align="center" justify="space-between">
                        <v-col cols="12" md="">
                            <h4 class="tit tit--sm line-height-1">
                                개인정보 제 3자 제공 동의
                            </h4>
                        </v-col>
                        <v-col cols="12" md="" class="d-flex justify-end">
                            <v-radio-group v-model="agree2" row hide-details class="v-radio-group--sm">
                                <v-radio label="동의함" :value="true"/>
                                <v-radio label="동의안함" :value="false"/>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </div>
                <v-card elevation="0" outlined style="border-color: #ececec">
                    <div class="pa-20">
                        <ul class="dot-texts page-text page-text--sm">
                            <li class="dot-text dot-text--sm">
                                개인정보를 제공 받는 자: 효성에프엠에스(주), 금융기관, 통신사, 국세청(홈택스)
                            </li>
                            <li class="dot-text dot-text--sm">
                                개인정보를 제공받는 자의 이용목적 : 자동이체서비스 제공 및 자동이체 동의 사실 통지
                            </li>
                            <li class="dot-text dot-text--sm">
                                제공하는 개인정보의 항목 : 성명, 생년월일, 연락처, 은행명, 예금주명, 계좌번호, 예금주 휴대전화번호
                            </li>
                            <li class="dot-text dot-text--sm">
                                개인정보를 제공 받는 자의 개인정보 보유 및 이용 기간 : 동의일로부터 자동이체의 종료일(해지일)까지. 단, 관계 법령에 의거 일정기간 동안 보관
                            </li>
                            <li class="dot-text dot-text--sm">
                                신청자는 개인정보에 대해 수납업체가 제3자에 제공하는 것을 거부할 수 있습니다. 단, 거부 시 자동이체 신청이 처리되지 않습니다.
                            </li>
                        </ul>
                    </div>
                </v-card>
            </div>
            <v-card elevation="0" outlined color="primary">
                <div class="pa-20" style="background-color: #ECF7E9">
                    <h5 class="font-size-16 font-size-lg-20 line-height-1 mb-8 mb-lg-16">
                        출금이체 동의여부 및 해지사실 통지안내
                    </h5>
                    <p class="page-text page-text--sm line-height-15">
                        효성에프엠에스(주) 및 금융기관은 안전한 서비스의 제공을 위하여 예금주 휴대전화번호로 자동이체 동의 사실을 SMS(또는 LMS)로 통지합니다.
                    </p>
                </div>
            </v-card>
        </div>

        <div class="mb-40 mb-lg-60">
            <div class="text-center mb-16 mb-lg-24">
                <h5 class="page-text page-text--lg font-weight-medium grey-1--text mb-4 mb-lg-12">
                    신청인(예금주)은 신청정보, 금융거래정보 등 개인정보의 수집·이용 및 제3자 제공에 동의하며 상기와 같이 효성CMS 자동이체를 신청합니다.
                </h5>
                <p class="page-text page-text--sm">
                    ※ 귀하의 소중한 정보는 개인정보보호법에 의거 안전하게 보호되어지며, 후원 해지 시 모든 정보가 폐기되어 집니다.
                </p>
            </div>
            <v-card elevation="0" color="grey-f5">
                <div class="pa-20 pa-md-30 pa-lg-40">
                    <v-row class="row--large">
                        <v-col cols="12" lg="auto">
                            <v-row class="row--small" align="center">
                                <v-col cols="6" lg="auto">
                                    <v-row class="row--x-small" align="center">
                                        <v-col cols="auto">
                                            <p class="page-text page-text--sm">
                                                신청인:
                                            </p>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="subscriber" outlined hide-details class="mw-lg-180px" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="6" lg="auto">
                                    <v-row class="row--x-small" align="center">
                                        <v-col cols="auto">
                                            <p class="page-text page-text--sm">
                                                서명
                                            </p>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="subscriberSign" outlined hide-details class="mw-lg-180px" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="d-block d-lg-none mt-4 mb-n4">
                            <p class="page-text page-text--sm">
                                (신청인과 예금주가 다를경우)
                            </p>
                        </v-col>
                        <v-col cols="12" lg="auto">
                            <v-row class="row--small" align="center">
                                <v-col cols="6" lg="auto">
                                    <v-row class="row--x-small" align="center">
                                        <v-col cols="auto">
                                            <p class="page-text page-text--sm">
                                                <span class="d-none d-lg-inline">(신청인과 예금주가 다를경우)</span> 예금주:
                                            </p>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="accountHolder" outlined hide-details class="mw-lg-180px" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="6" lg="auto">
                                    <v-row class="row--x-small" align="center">
                                        <v-col cols="auto">
                                            <p class="page-text page-text--sm">
                                                서명
                                            </p>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="accountHolderSign" outlined hide-details class="mw-lg-180px" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
        
        <p class="page-text page-text--lg grey-1--text line-height-1 text-center">
            {{$dayjs(new Date).format("YYYY년 MM월 DD일")}}
        </p>

        <div class="v-btn--group">
            <v-btn large @click="submit" color="primary" class="min-w-140px min-w-lg-160px">작성완료</v-btn>
        </div>

        <!-- 후원방법 -->
        <!-- <div>
            <v-card-title>후원방법</v-card-title>
            <v-row>
                <v-col>후원유형</v-col>
                <v-col>{{code}}</v-col>
            </v-row>
            <v-row>
                <v-col>은행명 *</v-col>
                <v-col><v-text-field v-model="sponsor.bank"/></v-col>
            </v-row>
            <v-row>
                <v-col>예금주 *</v-col>
                <v-col><v-text-field v-model="sponsor.accountHolder"/></v-col>
            </v-row>
            <v-row>
                <v-col>계좌번호 *</v-col>
                <v-col><v-text-field v-model="sponsor.accountNum"/></v-col>
            </v-row>
            <v-row>
                <v-col>예금주 생년월일 *</v-col>
                <v-col><v-text-field v-model="sponsor.birth"/></v-col>
                <v-col>*개인: 주민번호 앞 6자리, *법인: 사업자등록번호 10자리</v-col>
            </v-row>
            <v-row>
                <v-col>예금주 연락처</v-col>
                <v-col><v-select :items="prependPhones" v-model="sponsor.phone1"/><v-text-field v-model="sponsor.phone2"/></v-col>
            </v-row>
            <v-row>
                <v-col>약정금액 *</v-col>
                <v-col>
                    <v-radio-group v-model="amount.support">
                        <v-radio label="1만원" value="1만원"/>
                        <v-radio label="2만원" value="2만원"/>
                        <v-radio label="3만원" value="3만원"/>
                        <v-radio label="5만원" value="5만원"/>
                        <v-radio label="10만원" value="10만원"/>
                        <v-radio label="기타" value="기타"></v-radio>
                        <v-text-field v-model="amount.priceEtc" v-if="amount.support === '기타'" outlined persistent-placeholder hide-details />
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col>출금일 *</v-col>
                <v-col>
                    <v-radio-group v-model="amount.day">
                        <v-radio label="1일" value="1일"/>
                        <v-radio label="10일" value="10일"/>
                        <v-radio label="25일" value="25일"/>
                        <v-radio label="30일" value="30일"/>
                        <v-radio label="기타" value="기타"></v-radio>
                        <v-text-field v-model="amount.dayEtc" v-if="amount.day === '기타'" outlined persistent-placeholder hide-details />
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col>기타 요청사항</v-col>
                <v-col><v-text-field v-model="memo" label="요청사항을 적어주세요."/></v-col>
            </v-row>
        </div> -->

        <!-- 기본정보 -->
        <!-- <div>
            <v-card-title>기본정보</v-card-title>
            <v-row>
                <v-col>성명(단체명) *</v-col>
                <v-text-field v-model="primary.name" label="이름 혹은 단체명을 입력하세요."/>
            </v-row>
            <v-row>
                <v-col>추천인 *</v-col>
                <v-text-field v-model="primary.recommender" label="추천인 이름을 입력하세요."/>
            </v-row>
            <v-row>
                <v-col>주민등록번호 *</v-col>
                <v-text-field v-model="primary.residentNum1" label="주민등록번호 앞자리를 입력하세요."/>
                <v-text-field v-model="primary.residentNum2" label="주민등록번호 뒷자리를 입력하세요."/>
            </v-row>
            <v-row>
                <v-col>연락처 *</v-col>
                <v-col><v-select :items="prependPhones" v-model="primary.phone1"/><v-text-field v-model="primary.phone2"/></v-col>
            </v-row>
            <v-row>
                <v-col>주소 *</v-col>
                <v-col>
                    <v-text-field v-model="primary.postcode" @click="searchAddress" placeholder="우편번호"></v-text-field>
                </v-col>
                <v-col>
                    <v-btn @click="searchAddress">검색</v-btn>
                </v-col>
                <v-col>
                    <v-text-field v-model="primary.address1" @click="searchAddress" placeholder="기본주소"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field v-model="primary.address2" type="text" placeholder="상세주소"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>소득공제 영수증</v-col>
                <v-radio-group v-model="primary.tax">
                    <v-radio label="필요" value="true"/>
                    <v-radio label="불필요" value="false"/>
                </v-radio-group>
            </v-row>
        </div> -->
        <!-- <div>
            <v-card-title>개인정보 수집 및 이용 동의</v-card-title>
            <v-radio-group v-model="agree1">
                <v-radio label="동의함" :value="true"/>
                <v-radio label="동의안함" :value="false"/>
            </v-radio-group>
        </div>
        <div>
            <v-card-title>개인정보 제 3자 제공 동의</v-card-title>
            <v-radio-group v-model="agree2">
                <v-radio label="동의함" :value="true"/>
                <v-radio label="동의안함" :value="false"/>
            </v-radio-group>
        </div> -->

        <!-- <div>
            출금이체 동의여부 및 해지사실 통지안내
        </div>
        <div>
            신청인(예금주)은 신청정보, 금융거래정보 등 개인정보의 수집이용 및 제 3자 제공에 동의하며 상기와 같이 효성CMS 자동이체를 신청합니다.
        </div>


        <div>
            <v-row>
                <v-col>신청인: </v-col>
                <v-col><v-text-field v-model="subscriber"/></v-col>
                <v-col>서명: </v-col>
                <v-col><v-text-field v-model="subscriberSign"/></v-col>
                <v-col>(신청인과 예금주가 다를경우)예금주: </v-col>
                <v-col><v-text-field v-model="accountHolder"/></v-col>
                <v-col>서명: </v-col>
                <v-col><v-text-field v-model="accountHolderSign"/></v-col>
            </v-row>
        </div> -->

        <!-- <div>
            <v-row>
                <v-col><v-btn @click="this.$router.go(-1)">취소</v-btn></v-col>
                <v-col><v-btn @click="submit">작성완료</v-btn></v-col>
            </v-row>
        </div> -->

        <daum-postcode ref="daum-postcode" @change="({ postcode, address }) => { primary.postcode = postcode; primary.address1 = address; }"></daum-postcode>

    </div>
</template>

<script>
import FormRow from "@/components/client/sub/form-row.vue";
import api from "@/api";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";

export default {
    components:{
        FormRow,
        DaumPostcode,
    },
    props:{
        code: String
    },
    data() {
        return {
            campaign: null,
            support: null,

            agree1: null,
            agree2: null,

            code: null,

            sponsor:{
                bank: null,
                accountHolder:null,
                accountNum: null,
                birth: null,
                phone: null,
                phone1: "010",
                phone2: null,
                amount: {
                    support: null,
                    priceEtc: null,
    
                    day:null,
                    dayEtc:null,
                },
                memo: null,
            },

            primary:{
                name: null,
                recommender: null,
                residentNum: null,
                residentNum1: null,
                residentNum2: null,
                phone: null,
                phone1: "010",
                phone2: null,
                postcode: "",
                address: "",
                address1: "",
                address2: "",
                tax: null
            },

            subscriber: null,
            subscriberSign: null,
            accountHolder: null,
            accountHolderSign: null,

            prependPhones: [
                { text: "010", value: "010" },
                { text: "011", value: "011" },
                { text: "016", value: "016" },
                { text: "017", value: "017" },
                { text: "019", value: "019" }
            ],
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        async init(){
            if(this.$route.query._campaign) {
                let { support } = await api.v1.supports.donations.get({ _id: this.$route.query._campaign });
                this.campaign = support.subject;
            }
        },
        validate(){
            try{
                let sponsor = this.sponsor
                let primary = this.primary
                if(!sponsor.bank) throw new Error('예금하실 은행명을 입력해주세요')
                if(!sponsor.accountHolder) throw new Error('예금주명을 입력해주세요')
                if(!sponsor.accountNum) throw new Error('계좌번호를 입력해주세요')
                if(!sponsor.birth) throw new Error('생년월일 (혹은 사업자번호)을 입력해주세요')
                if(!sponsor.phone || !sponsor.phone1 || !sponsor.phone2) throw new Error('연락처를 입력해주세요')
                if(sponsor.amount.support === '기타' || sponsor.amount.day === '기타') {
                    if(!sponsor.amount.priceEtc && !sponsor.amount.dayEtc ) throw new Error('기타를 입력해주세요');
                }

                if(!primary.name) throw new Error('이름 혹은 단체명을 입력하세요.')
                if(!primary.phone) throw new Error('연락처를 입력해주세요')
                if(!primary.postcode || !primary.address1 || !primary.address2) throw new Error('주소를 입력해주세요')
                if(!primary.tax) throw new Error('소득공제 유무에 체크해 주세요')
            
                if(!this.agree1) throw new Error('개인정보 수집·이용에 동의해주세요')
                if(!this.agree2) throw new Error('개인정보 제3자 제공 동의에 동의해주세요')
                if(!this.subscriber) throw new Error('신청인을 입력해주세요')
                if(!this.subscriberSign) throw new Error('서명을 입력해주세요')
                // if(!this.accountHolder) throw new Error('예금주를 입력해주세요')
                // if(!this.accountHolderSign) throw new Error('서명을 입력해주세요')
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async submit(){
            if(this.validate()){
                let primary = this.primary
                primary.address = primary.address1 +primary.address2
                // let { success } = await api.v1.message.post({ demo })
                if(this.$route.query._campaign){
                    let { success } = await api.v1.forms.post({ 
                        code : this.$props.code,
                        sponsor: this.sponsor,
                        primary: this.primary,
                        subscriber: this.subscriber,
                        subscriberSign: this.subscriberSign,
                        accountHolder: this.accountHolder,
                        accountHolderSign: this.accountHolderSign,
                        type: this.campaign
                    })
                    if(success){
                        alert('후원 신청이 완료됐습니다.')
                        this.$router.push('/')
                    }
                }
                else {
                    let { success } = await api.v1.forms.post({ 
                        code : this.$props.code,
                        sponsor: this.sponsor,
                        primary: this.primary,
                        subscriber: this.subscriber,
                        subscriberSign: this.subscriberSign,
                        accountHolder: this.accountHolder,
                        accountHolderSign: this.accountHolderSign,
                    })
                    if(success){
                        alert('후원 신청이 완료됐습니다.')
                        this.$router.push('/')
                    }
                }
            }
        },
        searchAddress() {
            this.$refs["daum-postcode"].open();
        },
        setAddress({ postcode, address }) {
            this.primary.postcode = postcode;
            this.primary.address = address;
        },
    },
    watch:{
        "sponsor.phone1"(){
            this.sponsor.phone = this.sponsor.phone1 + this.sponsor.phone2
        },
        "sponsor.phone2"(){
            this.sponsor.phone = this.sponsor.phone1 + this.sponsor.phone2
        },
        "primary.phone1"(){
            this.primary.phone = this.primary.phone1 + this.primary.phone2
        },
        "primary.phone2"(){
            this.primary.phone = this.primary.phone1 + this.primary.phone2
        },
    }
}
</script>

<style>

</style>