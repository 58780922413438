var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-40 mb-lg-60 text-center"
  }, [_c('h3', {
    staticClass: "tit tit--md"
  }, [_vm._v(" " + _vm._s(_vm.code) + " ")])]), _c('div', {
    staticClass: "mb-40 mb-lg-60"
  }, [_c('div', {
    staticClass: "mb-8 mb-lg-20"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(" 후원방법 ")])]), _c('v-col', [_c('p', {
    staticClass: "form__caption mb-0"
  }, [_vm._v("* 필수입력 항목입니다.")])])], 1)], 1), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "후원유형"
    }
  }, [_c('p', {
    staticClass: "font-size-14 grey-6--text"
  }, [_vm._v(_vm._s(_vm.code))])]), _vm.$route.query._campaign ? _c('form-row', {
    attrs: {
      "tit": "캠페인"
    }
  }, [_c('p', {
    staticClass: "font-size-14 grey-6--text"
  }, [_vm._v(_vm._s(_vm.campaign))])]) : _vm._e(), _c('form-row', {
    attrs: {
      "tit": "은행명",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "예금하실 은행명을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.bank,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor, "bank", $$v);
      },
      expression: "sponsor.bank"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "예금주",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "예금주명을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.accountHolder,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor, "accountHolder", $$v);
      },
      expression: "sponsor.accountHolder"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "계좌번호",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "계좌번호를 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.accountNum,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor, "accountNum", $$v);
      },
      expression: "sponsor.accountNum"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "예금주 생년월일",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "생년월일을 입력하세요.",
      "hint": "*개인: 주민번호 앞 6자리, *법인: 사업자등록번호 10자리",
      "persistent-hint": "",
      "outlined": ""
    },
    model: {
      value: _vm.sponsor.birth,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor, "birth", $$v);
      },
      expression: "sponsor.birth"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "예금주 연락처",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "lg": "auto"
    }
  }, [_c('v-select', {
    staticClass: "w-lg-200px",
    attrs: {
      "items": _vm.prependPhones,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor, "phone1", $$v);
      },
      expression: "sponsor.phone1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "lg": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "‘-’를 제외하고 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor, "phone2", $$v);
      },
      expression: "sponsor.phone2"
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "약정금액",
      "pointer": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.amount.support,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor.amount, "support", $$v);
      },
      expression: "sponsor.amount.support"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "1만원",
      "value": "1만원"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "2만원",
      "value": "2만원"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "3만원",
      "value": "3만원"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "5만원",
      "value": "5만원"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "10만원",
      "value": "10만원"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "기타",
      "value": "기타"
    }
  }), _vm.sponsor.amount.support === '기타' ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    staticClass: "w-60px",
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.amount.priceEtc,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor.amount, "priceEtc", $$v);
      },
      expression: "sponsor.amount.priceEtc"
    }
  }), _c('p', {
    staticClass: "font-size-14 grey-6--text ml-4 ml-lg-8"
  }, [_vm._v("만원")])], 1) : _vm._e()], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "출금일",
      "pointer": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.amount.day,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor.amount, "day", $$v);
      },
      expression: "sponsor.amount.day"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "1일",
      "value": "1일"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "10일",
      "value": "10일"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "25일",
      "value": "25일"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "30일",
      "value": "30일"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "기타",
      "value": "기타"
    }
  }), _vm.sponsor.amount.day === '기타' ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    staticClass: "w-60px",
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.amount.dayEtc,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor.amount, "dayEtc", $$v);
      },
      expression: "sponsor.amount.dayEtc"
    }
  }), _c('p', {
    staticClass: "font-size-14 grey-6--text ml-4 ml-lg-8"
  }, [_vm._v("일")])], 1) : _vm._e()], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "기타 요청사항"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rows": "9",
      "placeholder": "요청사항을 적어주세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.sponsor.memo,
      callback: function ($$v) {
        _vm.$set(_vm.sponsor, "memo", $$v);
      },
      expression: "sponsor.memo"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "mb-40 mb-lg-60"
  }, [_c('div', {
    staticClass: "mb-8 mb-lg-20"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(" 기본정보 ")])]), _c('v-col', [_c('p', {
    staticClass: "form__caption mb-0"
  }, [_vm._v("* 필수입력 항목입니다.")])])], 1)], 1), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "성명(단체명)",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "이름을 혹은 단체명을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.primary.name,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "name", $$v);
      },
      expression: "primary.name"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "추천인",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "추천인 이름을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.primary.recommender,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "recommender", $$v);
      },
      expression: "primary.recommender"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "주민등록번호",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "주민등록번호 앞자리를 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.primary.residentNum1,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "residentNum1", $$v);
      },
      expression: "primary.residentNum1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "주민등록번호 뒷자리를 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.primary.residentNum2,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "residentNum2", $$v);
      },
      expression: "primary.residentNum2"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-4 px-16"
  }, [_c('p', {
    staticClass: "font-size-12 grey-9--text"
  }, [_vm._v("*소득공제 영수증 발급을 원하실 경우 주민등록번호가 필요합니다.")])])], 1), _c('form-row', {
    attrs: {
      "tit": "연락처",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "lg": "auto"
    }
  }, [_c('v-select', {
    staticClass: "w-lg-200px",
    attrs: {
      "items": _vm.prependPhones,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.primary.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "phone1", $$v);
      },
      expression: "primary.phone1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "lg": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "‘-’를 제외하고 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.primary.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "phone2", $$v);
      },
      expression: "primary.phone2"
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "주소",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": "",
      "placeholder": "우편번호",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "click": _vm.searchAddress
    },
    model: {
      value: _vm.primary.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "postcode", $$v);
      },
      expression: "primary.postcode"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100 w-80px w-lg-100px",
    attrs: {
      "large": "",
      "color": "grey-6"
    },
    on: {
      "click": _vm.searchAddress
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("검색")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": "",
      "placeholder": "기본주소",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "click": _vm.searchAddress
    },
    model: {
      value: _vm.primary.address1,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "address1", $$v);
      },
      expression: "primary.address1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "상세주소",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.primary.address2,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "address2", $$v);
      },
      expression: "primary.address2"
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "소득공제 영수증",
      "pointer": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.primary.tax,
      callback: function ($$v) {
        _vm.$set(_vm.primary, "tax", $$v);
      },
      expression: "primary.tax"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "필요",
      "value": "true"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "불필요",
      "value": "false"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "mb-40 mb-lg-60"
  }, [_c('div', {
    staticClass: "mb-16 mb-lg-24"
  }, [_c('div', {
    staticClass: "mb-8 mb-lg-16"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(" 개인정보 수집 및 이용 동의 ")])]), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-radio-group', {
    staticClass: "v-radio-group--sm",
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.agree1,
      callback: function ($$v) {
        _vm.agree1 = $$v;
      },
      expression: "agree1"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "동의함",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "동의안함",
      "value": false
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticStyle: {
      "border-color": "#ececec"
    },
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20"
  }, [_c('ul', {
    staticClass: "dot-texts page-text page-text--sm"
  }, [_c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 수집 및 이용목적 : 효성 CMS 출금이체를 통한 요금수납, 소득공제 영수증 발급 ")]), _c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 수집항목 : 성명, 생년월일, 연락처, 예금주명, 계좌번호, 예금주 휴대전화번호 ")]), _c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 보유 및 이용 기간 : 수집, 이용 동의 일로부터 자동이체 종료일(해지일) 까지 ")]), _c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 신청자는 개인정보 수집 및 이용을 거부할 권리가 있으며, 권리 행사 시 출금이체 신청이 거부될 수 있습니다. ")])])])])], 1), _c('div', {
    staticClass: "mb-16 mb-lg-24"
  }, [_c('div', {
    staticClass: "mb-8 mb-lg-16"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(" 개인정보 제 3자 제공 동의 ")])]), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-radio-group', {
    staticClass: "v-radio-group--sm",
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.agree2,
      callback: function ($$v) {
        _vm.agree2 = $$v;
      },
      expression: "agree2"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "동의함",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "동의안함",
      "value": false
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticStyle: {
      "border-color": "#ececec"
    },
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20"
  }, [_c('ul', {
    staticClass: "dot-texts page-text page-text--sm"
  }, [_c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 개인정보를 제공 받는 자: 효성에프엠에스(주), 금융기관, 통신사, 국세청(홈택스) ")]), _c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 개인정보를 제공받는 자의 이용목적 : 자동이체서비스 제공 및 자동이체 동의 사실 통지 ")]), _c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 제공하는 개인정보의 항목 : 성명, 생년월일, 연락처, 은행명, 예금주명, 계좌번호, 예금주 휴대전화번호 ")]), _c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 개인정보를 제공 받는 자의 개인정보 보유 및 이용 기간 : 동의일로부터 자동이체의 종료일(해지일)까지. 단, 관계 법령에 의거 일정기간 동안 보관 ")]), _c('li', {
    staticClass: "dot-text dot-text--sm"
  }, [_vm._v(" 신청자는 개인정보에 대해 수납업체가 제3자에 제공하는 것을 거부할 수 있습니다. 단, 거부 시 자동이체 신청이 처리되지 않습니다. ")])])])])], 1), _c('v-card', {
    attrs: {
      "elevation": "0",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "pa-20",
    staticStyle: {
      "background-color": "#ECF7E9"
    }
  }, [_c('h5', {
    staticClass: "font-size-16 font-size-lg-20 line-height-1 mb-8 mb-lg-16"
  }, [_vm._v(" 출금이체 동의여부 및 해지사실 통지안내 ")]), _c('p', {
    staticClass: "page-text page-text--sm line-height-15"
  }, [_vm._v(" 효성에프엠에스(주) 및 금융기관은 안전한 서비스의 제공을 위하여 예금주 휴대전화번호로 자동이체 동의 사실을 SMS(또는 LMS)로 통지합니다. ")])])])], 1), _c('div', {
    staticClass: "mb-40 mb-lg-60"
  }, [_vm._m(0), _c('v-card', {
    attrs: {
      "elevation": "0",
      "color": "grey-f5"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-40"
  }, [_c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v(" 신청인: ")])]), _c('v-col', [_c('v-text-field', {
    staticClass: "mw-lg-180px",
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.subscriber,
      callback: function ($$v) {
        _vm.subscriber = $$v;
      },
      expression: "subscriber"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v(" 서명 ")])]), _c('v-col', [_c('v-text-field', {
    staticClass: "mw-lg-180px",
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.subscriberSign,
      callback: function ($$v) {
        _vm.subscriberSign = $$v;
      },
      expression: "subscriberSign"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-block d-lg-none mt-4 mb-n4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v(" (신청인과 예금주가 다를경우) ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm"
  }, [_c('span', {
    staticClass: "d-none d-lg-inline"
  }, [_vm._v("(신청인과 예금주가 다를경우)")]), _vm._v(" 예금주: ")])]), _c('v-col', [_c('v-text-field', {
    staticClass: "mw-lg-180px",
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.accountHolder,
      callback: function ($$v) {
        _vm.accountHolder = $$v;
      },
      expression: "accountHolder"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v(" 서명 ")])]), _c('v-col', [_c('v-text-field', {
    staticClass: "mw-lg-180px",
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.accountHolderSign,
      callback: function ($$v) {
        _vm.accountHolderSign = $$v;
      },
      expression: "accountHolderSign"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1), _c('p', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs(new Date()).format("YYYY년 MM월 DD일")) + " ")]), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("작성완료")])], 1), _c('daum-postcode', {
    ref: "daum-postcode",
    on: {
      "change": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        _vm.primary.postcode = postcode;
        _vm.primary.address1 = address;
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center mb-16 mb-lg-24"
  }, [_c('h5', {
    staticClass: "page-text page-text--lg font-weight-medium grey-1--text mb-4 mb-lg-12"
  }, [_vm._v(" 신청인(예금주)은 신청정보, 금융거래정보 등 개인정보의 수집·이용 및 제3자 제공에 동의하며 상기와 같이 효성CMS 자동이체를 신청합니다. ")]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v(" ※ 귀하의 소중한 정보는 개인정보보호법에 의거 안전하게 보호되어지며, 후원 해지 시 모든 정보가 폐기되어 집니다. ")])]);

}]

export { render, staticRenderFns }